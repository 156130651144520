import React, { useState } from 'react';
import { motion } from 'framer-motion';

/**
 * @returns {JSX.Element}
 * @constructor
 */
const SupportFaq = ({ header, faqItems }) => {
  const [ activeIndex, setActiveIndex ] = useState(null);
  const [ childActiveIndex, setChildActiveIndex ] = useState(null);
  const { subtitle, title, shortDescription } = header;
  const parentAccordionClick = idx => {
    setChildActiveIndex(null);
    setActiveIndex(activeIndex === idx ? null : idx);
  };

  const childAccordionClick = idx => {
    setChildActiveIndex(childActiveIndex === idx ? null : idx);
  };

  const getOffset = el => {
    if (!el.getClientRects().length) {
      return { top: 0, left: 0 };
    }

    const rect = el.getBoundingClientRect();
    const win = el.ownerDocument.defaultView;
    return {
      top: rect.top + win.pageYOffset - 100,
      left: rect.left + win.pageXOffset,
    };
  };

  const scrollToElement = el => {
    const offset = getOffset(el).top;

    window.scrollTo({ behavior: 'smooth', top: offset });
  };

  const scrollToGoogleHome = e => {
    e.preventDefault();
    const googlehome = document.getElementById('googlehome');
    scrollToElement(googlehome);
  };

  const scrollToAlexa = e => {
    e.preventDefault();
    const alexa = document.getElementById('alexa');
    scrollToElement(alexa);
  };

  const handlers = {
    scrollToGoogleHome,
    scrollToAlexa,
  };

  return (
    <section id='faq' className='support-faq support-block'>
      <div className='container'>
        <div className='support-faq__inner'>
          <div className='support-faq__intro'>
            {subtitle && (
              <h2 className='support-faq__intro-subtitle'>{subtitle}</h2>
            )}
            {title && <h3 className='support-faq__intro-title'>{title}</h3>}
            {shortDescription && (
              <div className='support-faq__intro-description'>
                {shortDescription}
              </div>
            )}
          </div>
          <div className='accordion-items'>
            {faqItems?.map(({ heading, content, id }, parentIdx) => (
              <div
                id={id}
                className={`accordion-items__parent ${
                  activeIndex === parentIdx ? 'is-active' : ''
                }`}
                key={heading}
              >
                {heading && (
                  <div
                    className='accordion-items__header'
                    onClick={() => parentAccordionClick(parentIdx)}
                    aria-expanded={`${
                      activeIndex === parentIdx ? 'true' : 'false'
                    }`}
                  >
                    <h3 className='accordion-items__title'>{heading}</h3>
                    <svg className='icon icon-down-arrow-alt'>
                      <use xlinkHref='#icon-down-arrow-alt' />
                    </svg>
                  </div>
                )}

                <motion.div
                  className='accordion-items__list'
                  initial={{ height: 0 }}
                  animate={{ height: activeIndex === parentIdx ? 'auto' : 0 }}
                  transition={{ duration: 0.4 }}
                  style={{ overflow: 'hidden' }}
                  aria-hidden={`${
                    activeIndex === parentIdx ? 'false' : 'true'
                  }`}
                >
                  <div className='accordion-items__list-inner'>
                    {content?.map(({ title, details }, childIdx) => (
                      <div className='accordion-item' key={title}>
                        {title && (
                          <div
                            className='accordion-item__header'
                            onClick={() => childAccordionClick(childIdx)}
                          >
                            <h3 className='accordion-item__title'>{title}</h3>
                          </div>
                        )}
                        <motion.div
                          className='accordion-item__body'
                          initial={{ height: 0 }}
                          animate={{
                            height:
                              activeIndex === parentIdx
                              && childActiveIndex === childIdx
                                ? 'auto'
                                : 0,
                          }}
                          transition={{ duration: 0.4 }}
                          style={{ overflow: 'hidden' }}
                          aria-hidden={`${
                            activeIndex === parentIdx
                            && childActiveIndex === childIdx
                              ? 'false'
                              : 'true'
                          }`}
                        >
                          <div className='accordion-item__content'>
                            {typeof details === 'function'
                              ? details(handlers)
                              : details}
                          </div>
                        </motion.div>
                      </div>
                    ))}
                  </div>
                </motion.div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default SupportFaq;
